import React from "react";
import { connect } from "redux-bundler-react";

import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }


  const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

class Main extends React.Component {
  componentDidMount() {
   
  }
  openInNewTab (url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  render() {
    
   
    return (
        <div>
      
      <main>
        {/* Hero unit */}
         {/* <Grid container component="main" sx={{ height: '100vh' }}> */}
        {/* <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        /> */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Extreme Storms Database
            </Typography>
            <Typography variant="h6" align="center" color="text.secondary" paragraph>
            The Extreme Storms Database is comprised of original storm data, 
                    either in a GIS format based on NOAA gridded radar data information or from 
                    scanned archival sources such as storm atlases or event reports. 
                    The extracted information is in the form of a GIS or geo-referenced scanned pages with information on specific 
                    storm events and loaded into a database. Some storm event data has had additional processing to compute storm information and metrics 
                    such as storm environment, storm area, total rainfall, etc. The website is designed to serve as the main source for historic storm data, 
                    including depth area duration tables and precipitation totals for a given storm event.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >  
            <Button variant="contained" onClick={() => this.openInNewTab('https://xstorms.sec.usace.army.mil/download/')}>
              Download
                        
                    </Button> 
            {/* <Button  variant="outlined" href="https://xstorms.sec.usace.army.mil/upload/" >
                        Upload
                      </Button> */}
            </Stack>
          </Container>
        </Box>
        {/* </Grid> */}
      </main>
      {/* Footer */}
      {/* <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
        <Copyright />
      </Box> */}
      {/* End footer */}
      </div>
    );
  }
}

export default connect(
  
  Main
);