import {
    composeBundles,
    createCacheBundle,
    createUrlBundle,
  } from "redux-bundler";
  import cache from "../utils/cache";

  import healthBundle from "./health-bundle";
  import nestedUrlBundle from "./nested-url-bundle";
  import routeBundle from "./route-bundle";
  export default composeBundles(
    createCacheBundle({
      cacheFn: cache.set,
    }),
    createUrlBundle(),
    healthBundle,
    nestedUrlBundle,
    routeBundle,
  );