import { createRouteBundle } from "redux-bundler";
import Home from "../components/app-home/home";


import FourOhFour from "../components/four-oh-four";


export default createRouteBundle(
  {
    "": Home,
    "/": Home,    
    "*": FourOhFour,
  },
  {
    routeInfoSelector: "selectPathnameMinusHomepage",
  }
);
