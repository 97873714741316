import React from "react";
import { Provider } from "redux-bundler-react";
import { getNavHelper } from "internal-nav-helper";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MainContainer from "./containers/main-container";
const theme = createTheme();

class App extends React.Component {
  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
         
        <div onClick={getNavHelper(store.doUpdateUrlWithHomepage)}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
              <MainContainer />
            
          </ThemeProvider>
        </div>
      </Provider>
    );
  }
}

export default App;
