import React from "react";
import { connect } from "redux-bundler-react";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.renderContent = this.renderContent.bind(this);
  }

  renderContent() {
    
    
      return (
        <div style={{ marginTop: "50%", marginBottom: "50%" }}>
          
        </div>
      );
    
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

export default connect(
  Home
);
