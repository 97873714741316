import xhr from "xhr";
import { createSelector } from "redux-bundler";



export default {
  name: "health",

  getReducer: () => {
    const initialState = {
        isFetching:false,
        checked:false,
        condition:null,
        items:"",
        err:null
    };

    return (state = initialState, { type, payload }) => {
        switch (type) {
          case "HEALTH_CHECK_STARTED":
          case "HEALTH_CHECK_ERROR":
          case "HEALTH_CHECK_FINISH":
            return Object.assign({}, state, payload);
          default:
            return state;
        }
      };
  },

  doHealthCheckApi: () => ({ dispatch, store, apiGet }) => {
    const url=process.env.REACT_APP_API_ROOT
    dispatch({
        type: "HEALTH_CHECK_STARTED",
        payload: { isFetching: true, err:null}
      });
    xhr.get(
        {
          url: url,
          withCredentials: false
        },
        (err, res, body) => {
          if (err) {
            dispatch({
              type: "HEALTH_CHECK_ERROR",
              payload: { items: "", err: err, isFetching: false, checked:true}
            });
          } else {
            try {
              
              // test parse the token to make sure it's an actual token
              
             const json = JSON.parse(body); // eslint-disable-line no-unused-vars
              // if we're still alive we should be ok.
              dispatch({
                type: "HEALTH_CHECK_FINISH",
                payload: { items: json, err: null, isFetching: false, checked:true}
              });
            } catch (e) {
              dispatch({
                type: "HEALTH_CHECK__ERROR",
                payload: { items: {}, err: e, isFetchingFY: false, checked:true  }
              });
            }
          }
        }
      );
    
  },
  selectHealthChecked: (state) => {
    return state.health.checked;
  },
  selectHealthCondition: (state) => {
    return state.health.condition;
  },
  selectHealthIsFetching: (state) => {
    return state.health.isFetching;
  },
  selectHealthItems: (state) => {
    return state.health.items;
  },
};
